import { post, App_ID } from "./publicHeader";

class Store {
  // 获取店铺列表
  async getStoreList(page) {
    try {
      const res = await post('/shop/user.store/index', {
        app_id: App_ID,
        page,
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 店铺详情
  async getStoreDetail(store_id) {
    try {
      const res = await post('/shop/user.goods/store', {
        app_id: App_ID,
        store_id
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 店铺分类
  async getStoreSort(merch_id, store_id) {
    try {
      const res = await post('/merch/user.merch/goodsCate', {
        app_id: App_ID,
        store_id,
        merch_id
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 收藏店铺
  async addStoreLove(store_id) {
    try {
      const res = await post('shop/user.Shopcollectionstore/collect', {
        app_id: App_ID,
        store_id,
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 店铺 猜你喜欢列表
  async getStoreGoodsList(store_id, buy_count = 1, like = 1) {
    try {
      const res = await post('/goods/user.goods/index', {
        app_id: App_ID,
        store_id,
        buy_count,
        like
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 取消收藏
  async outStoreLove(id) {
    try {
      const res = await post('shop/user.Shopcollectionstore/collect', {
        app_id: App_ID,
        id,
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  // 收藏列表
  async storeLoveList(page) {
    try {
      const res = await post('shop/user.Shopcollectionstore/index', {
        app_id: App_ID,
        page,
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  // 门店详情
  async storeDes(id, store_id) {
    try {
      const res = await post('offlinestore/user.store/detail', {
        app_id: App_ID,
        id,
        store_id
      })
      return res;
    } catch (err) {
      console.log(err);
    }
  }
}

export default new Store();

