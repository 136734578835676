<template>
  <div class="store-con">
    <div class="shop-det-baner" >
      <!--搜索栏-->
      <searchTop navType="store" class="top-search">
        <template v-slot:rightText>
          <span class="iconfont icongouwuche2"></span>
        </template>
      </searchTop>
      <!--店铺信息-->
      <div class="store-main" >
        <div class="store-main-logo">
          <img :src="store_info.logo" alt="">
        </div>
        <div class="store-main-info">
          <div class="store-main-info-title">{{store_info.title}}</div>
          <div class="store-main-info-detail">
            <div class="store-label">乐享好店</div>
            <div class="store-textcon">
              <span>综合评分</span>|
              <span>{{store_info.collect_num}}人收藏</span>
            </div>
          </div>
        </div>
        <div :class="[love_status ? 'add-star':'no-star']" @click="addLove">
          <span class="iconfont iconxingxing"></span>
          <span>收藏</span>
        </div>
      </div>
      <div class="store-tab">
        <ul>
          <li 
            :class="[tab.index == idx ? 'active':'']" 
            v-for="(item, idx) in tab.con" :key="idx"
            @click="tab.index = idx"
          >
            {{item}}
          </li>
        </ul>
      </div>
      <Swipe id="scrollTop" :show-indicators="false" :autoplay="3000" style="height:100%">
        <Swipe-item v-for="(image, index) in goodsDetail" :key="index" >
          <img v-lazy="image.url" />
        </Swipe-item>
      </Swipe>
    </div>
    <!--猜你喜欢-->
    <YourLike v-if="likeCon.status" class="like-pos" :comObj="likeCon"></YourLike>
    <!--列表-->
    <ShopList id="myscroll" type="store" :childTabArr="childTabArr" :firstId="firstId"></ShopList>
  </div>
</template>
<script>
import Store from "@/api/store";
import YourLike from "./you_like";
import { Swipe, SwipeItem } from 'vant';
import searchTop from "@components/public/searchTop";
import ShopList from "@/views/home/enjoyshop/ShopList";
export default {
  components: { Swipe, SwipeItem, searchTop, ShopList, YourLike },
  data() {
    return {
      love_status: true,
      back_color: {
        color: "#ffffff"
      },
      tab: {
        index: 0,
        con: ['首页', '商品', '活动', '上新', '热销'],
      },
      childTabArr: [],
      list_status: "",
      firstId: "",
      store_info: {},
      likeCon: {
        title: "猜你喜欢",
        arr: [],
        status: true
      },
      goodsDetail: [
        {
          url: "http://up.enterdesk.com/edpic_source/19/53/2a/19532a0ad9fff6d1c8713f959f6b3655.jpg"
        }
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getDetail();
      this.getLikeList();
      this.getGoodsType();
      window.addEventListener('scroll', this.setScroll, true);
    }, 100)
  },
  methods: {
    // 猜你喜欢列表
    async getLikeList() {
      try {
        const res = await Store.getStoreGoodsList(0);
        if (res.code == 200) {
          if (res.data.length > 0) {
            this.likeCon.arr = res.data;
            this.likeCon.status = true;
          } else {
            this.likeCon.status = false;
            throw("暂无猜你喜欢列表数据");
          }
        } else {
          throw(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 顶部搜索栏效果
    setScroll() {
      let tpScrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
      var el = document.getElementsByClassName('top-search');
      if (tpScrollTop > 50 ) {
        el[0].classList.add("fadein", "search-bg", "top-search");
      } else {
        el[0].classList.add("fadeout");
        el[0].classList.remove("search-bg");
      }
    },
    // 获取店铺详细信息
    async getDetail() {
      try {
        const res = await Store.getStoreDetail(this.$route.query.store_id);
        if (res.code == 200) {
          this.store_info = res.data;
          this.love_status = res.data.collect_status;
        } else {
          throw(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取商品分类
    async getGoodsType() {
      try {
        var mytabs = {};
        var { merch_id, store_id } = this.$route.query;
        mytabs = await Store.getStoreSort(merch_id, store_id);
        var arr = [];
        if (mytabs.code == 200) {
          if (mytabs.data.length > 0) {
            for (let i in mytabs.data) {
              let obj = {};
              obj.name = mytabs.data[i].name;
              obj.id = mytabs.data[i].id;
              arr.push(obj);
            }
            this.childTabArr = arr;
            this.firstId = arr[0].id;
          } else {
            this.list_status = false;
            throw("暂无商品分类");
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 收藏
    async addLove() {
      try {
        const res = await Store.addStoreLove(this.$route.query.store_id);
        if (res.code == 200) {
          this.$toast(res.msg);
          this.love_status = false;
        } else {
          throw(res.msg);
        }
      } catch (error) {
        this.$toast(error);
      }
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.setScroll, true)
  }
}
</script>
<style lang="scss" scoped>
.store-con {
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  .store-main {
    width: 100%;
    height: 50px;
    position: absolute;
    display: flex;
    top: 70px;
    align-items: center;
    padding: 0 12px;
    background: transparent;
    z-index: 100;
    .store-main-logo {
      width: 45px;
      height: 45px;
      background: #ffffff;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .add-star, .no-star {
      width: 55px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      background: linear-gradient(-90deg,rgba(255,128,0,1) 0%,rgba(255,177,1,1) 100%);
      border-radius: 10px;
      .iconxingxing {
        font-size: 10px;
        padding-right: 2px;
      }
    }
    .no-star {
      color: #999999;
      background: #fdfdfd;
    }
    .store-main-info {
      width: 70%;
      height: 100%;
      display: flex;
      padding: 4px;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
      .store-main-info-title {
        width: 100%;
        font-size: 14px;
        color: #ffffff;
        font-weight: 500;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      .store-main-info-detail {
        display: flex;
        .store-label {
          color: #ffffff;
          font-size: 12px;
          border-radius: 3px;
          padding: 1px 2px;
          font-size: 10px;
          background: #7348DD;
        }
        .store-textcon {
          color: #ffffff;
          margin-left: 8px;
        }
      }
    }
  }
  .shop-det-baner {
    width: 100%;
    height: 250px;
    position: relative;
    background: #ffffff;
    .top-search {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .search-bg {
    background: #EDCFC9;
  }
  .like-pos {
    position: relative;
    margin-top: -50px;
  }
  .store-tab {
    width: 100%;
    height: 28px;
    position: absolute;
    top: 140px;
    z-index: 999;
    ul {
      width: auto;
      height: 100%;
      color: #ffffff;
      padding: 0 20px;
      display: flex;
      font-size: 14px;
      justify-content: space-between;
      li, .active {
        display: inline-block;
        height: 100%;
      }
      .active {
        border-bottom: 1px solid #ffffff;
      }
    }
  }
}

.icongouwuche2 {
  margin-left: 10px;
  font-size: 26px;
  color: #ffffff;
}
</style>