<template>
  <div class="DiyShopmarket">
    <div class="title" :style="{'background': comObj.color}">
      {{comObj.title}}
    </div>
    <div class="sortFlex" :style="{'background': comObj.color}">
      <div class="listcon styleThree" v-for="(item, index) in comObj.arr" :key="index">
        <!-- 列表图片组件 -->
        <div class="ThreeImgStyle">
          <img :src="item.image[0].url" alt />
        </div>
        <!-- 标题 -->
        <div class="amendTitle">
          <span style="font-size:12px;">{{item.name}}</span>
        </div>

        <!--列表价格组件-->
        <div class="amendPrice">
          <span class="font14">¥{{item.price}}元</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["comObj"]
};
</script>

<style lang="scss" scoped>
.DiyShopmarket {
  padding: 0 10px;
  margin-bottom: 10px;
  .title {
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .sortFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #fff;
    .listcon {
      width: 100%;
      // height: 100%;
      position: relative;
      // background: #ffffff;
    }
    .styleThree {
      width: 33.333% !important;
      height: 180px !important;
      .ThreeImgStyle {
        width: 100%;
        padding: 5px;
        height: 120px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }
      }
      .amendTitle {
        padding: 0 12px;
        width: 100%;
        color: #4C4C4C!important;
        height: 36px;
        font-size: 16px;
        line-height: 18px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .amendPrice {
        padding: 0 12px;
        z-index: auto;
        width: 100%;
        height: 20px;
        font-size: 16px;
        line-height: 20px;
        text-align: left!important;
      }
      .font14 {
        font-size: 14px;
        color: rgb(238, 131, 9);
      }
    }
  }
}
</style>
